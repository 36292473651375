import { APIProvider, Map, MapEvent } from "@vis.gl/react-google-maps";
import { useApi } from "../../../api/useApi";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import Table from "react-bootstrap/Table";
import { FavoriteIcon } from "../../components/listing/FavoriteIcon";
import { styled } from "styled-components";
import { MapMarker, MapMarkerType } from "../../components/layout/MapMarker";
import { InfoTooltip } from "../../components/InfoTooltip";
import { CreamTypes } from "../../interfaces";
import { ListingExpanded, CreamUsage } from "./components";
import { SortableTitle } from "./components/SortableTitle";
import { PercentDecimalDouble, USDollar } from "../../utils";
import { LoadingIndicator } from "../../components";
import { Col, Container, Row } from "react-bootstrap";

const styles = [
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const TableWrapper = styled.div`
  height: calc(97vh - 374px);
  overflow-y: scroll;
`;

const StyledTable = styled(Table)`
  tr,
  td {
    border-color: #ebebfb !important;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
  }

  & tr.active td {
    background: rgba(235, 235, 251, 0.5);
    border: none;
    font-weight: bold !important;
  }
  & tr:hover td {
    background: rgba(235, 235, 251, 0.5) !important;
  }
`;

const TdText = styled.td`
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 200px;
`;

interface Props {
  isAdmin: boolean;
  isLoading: boolean;
  bbox: CreamTypes.BBox;
  selectedSorting: string;
  listings: ListingCompactView[];
  setListings: (listings: ListingCompactView[]) => void;
  activeListing?: Listing;
  loadListing: (listingKey: string) => Promise<void>;
  onMapChange: (map: CreamTypes.BBox) => void;
  onSortingChange: (sortingKey: string) => void;
  creamUsageAccepted: boolean;
  setCreamUsageAccepted: () => void;
}

export const CreamMain = (props: Props) => {
  const apiContext = useApi();

  const onChange = (event: MapEvent) => {
    const bounds = event.map.getBounds();
    if (!bounds) return;
    const { south, north, east, west } = bounds.toJSON();
    props.onMapChange({
      min_lon: west,
      min_lat: south,
      max_lon: east,
      max_lat: north,
      zoom_level: event.map.getZoom() ?? 10,
    });
  };

  const handleAddToFavorites = async (listingKey: string): Promise<void> => {
    if (apiContext?.isLoading) return;
    await apiContext?.addToFavorites(listingKey);
    props.setListings(
      props.listings.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = true;
        }
        return l;
      })
    );
  };
  
  
  const disableListing = async (listingKey: string): Promise<void> => {
    await apiContext?.disableListing(listingKey);
    props.setListings(
      props.listings.filter((l) => {
        if (l.listing_key === listingKey) {
          return false;
        }
        return true;
      })
    );
  };

  const handleRemoveFromFavorites = async (
    listingKey: string
  ): Promise<void> => {
    if (apiContext?.isLoading) return;
    await apiContext?.removeFromFavorites(listingKey);
    props.setListings(
      props.listings.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = false;
        }
        return l;
      })
    );
  };

  return (
    <>
      <APIProvider apiKey={"AIzaSyAafNtCtx8Q7fh6bbFQBi4bq4Br-mzVY9g"}>
        <Map
          // onBoundsChanged={onChange}
          onDragend={onChange}
          onZoomChanged={onChange}
          style={{
            height: 300,
          }}
          mapTypeId={"terrain"}
          mapTypeControl={false}
          styles={styles}
          defaultBounds={{
            south: props.bbox.min_lat,
            west: props.bbox.min_lon,
            north: props.bbox.max_lat,
            east: props.bbox.max_lon,
          }}
          streetViewControl={false}
          maxZoom={16}
          minZoom={6}
        >
          {props.listings.map((listing) => (
            <MapMarker
              type={MapMarkerType.Search}
              selected={
                props.activeListing?.listing_key === listing.listing_key
              }
              onClick={() => props.loadListing(listing.listing_key)}
              key={listing.listing_key}
              lat={listing.latitude}
              lng={listing.longitude}
              markerKey={listing.listing_key}
            />
          ))}
        </Map>
      </APIProvider>
      {!props.creamUsageAccepted && (
        <CreamUsage onAccept={() => props.setCreamUsageAccepted()} />
      )}
      <TableWrapper>
        {props.isLoading && <Container><Row><Col><LoadingIndicator /></Col></Row></Container>}
        {props.listings.length > 0 && props.creamUsageAccepted && (
          <>
            <StyledTable>
              <thead>
                <tr className='sticky'>
                  <th>Address</th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='beds'
                      label='Beds'
                    />
                  </th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='year'
                      label='YrBlt'
                    />
                  </th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='price'
                      label='Price'
                    />
                  </th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='rent'
                      label='Rent'
                    />
                  </th>
                  <th colSpan={2}>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='return'
                      label='Return'
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.listings.map((listing) => (
                  <>
                    <tr
                      key={listing.listing_key}
                      onClick={() => props.loadListing(listing.listing_key)}
                      className={
                        listing.listing_key === props.activeListing?.listing_key
                          ? "active"
                          : ""
                      }
                    >
                      <TdText>
                        <InfoTooltip
                          label={listing.full_address}
                          placement='top'
                        >
                          {listing.full_address}
                        </InfoTooltip>
                      </TdText>

                      <td>{listing.bedrooms_total}</td>
                      <td>{listing.year_built}</td>
                      <td>{USDollar.format(listing.list_price)}</td>
                      <td>
                        {listing.estimation?.rent_prediction
                          ? USDollar.format(listing.estimation?.rent_prediction)
                          : null}
                      </td>
                      <td>
                        {listing.estimation?.return_prediction
                          ? PercentDecimalDouble.format(
                              listing.estimation.return_prediction
                            ) + "%"
                          : null}
                      </td>
                      <td>
                        <FavoriteIcon
                          favorite={listing.favorite}
                          loading={false}
                          onClick={() =>
                            listing.favorite
                              ? handleRemoveFromFavorites(listing.listing_key)
                              : handleAddToFavorites(listing.listing_key)
                          }
                        />
                      </td>
                    </tr>
                    {listing.listing_key ===
                      props.activeListing?.listing_key && (
                      <ListingExpanded
                        isAdmin={props.isAdmin}
                        onListingDisable={disableListing}
                        listing={props.activeListing}
                      />
                    )}
                  </>
                ))}
              </tbody>
            </StyledTable>
          </>
        )}
      </TableWrapper>
    </>
  );
};
