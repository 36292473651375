export const RentomaticFooter = () => {
    return (
      <div className='footer fixedFooter'>
        <div>
          <a
            href='https://www.selfrealty.com/dmca'
            rel='noreferrer'
            target='_blank'
          >
            DMCA
          </a>{" "}
          ·{" "}
          <a
            href='https://www.selfrealty.com/privacy-policy'
            rel='noreferrer'
            target='_blank'
          >
            PRIVACY POLICY
          </a>{" "}
          ·{" "}
          <a
            href='https://capitol.texas.gov/tlodocs/82R/billtext/html/HB02973F.htm'
            rel='noreferrer'
            target='_blank'
          >
            SLAPP
          </a>{" "}
          ·{" "}
          <a
            href='https://www.selfrealty.com/contact'
            rel='noreferrer'
            target='_blank'
          >
            CONTACT
          </a>
        </div>
        <div>COPYRIGHT © SELF Realty, LLC</div>
      </div>
    );
  };