import React, { useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { useAuth } from "../ui/hooks/useAuth";
import { User } from "./interfaces/user";
import { Rentomatic, ListingCompactView, Listing, CoreLogicResponse } from "./interfaces/listing";
import { Proforma } from "./interfaces/proforma";
import { Dossier } from "./interfaces/dossier";
import { UserPreferences } from "./interfaces/preferences";

export const API_URL = process.env.REACT_APP_API_URL;

export interface ContextType {
  isLoading: boolean
  login: (username: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  resetPasswordConfirm: (
    password: string,
    password_confirm: string,
    data: any
  ) => Promise<any>;
  register: (user: Partial<User>) => Promise<any>;
  getTerms: (name: string, email: string) => Promise<any>;
  logout: () => Promise<void>;
  getListings: (
    filters: any,
    offset: number,
    sort_by: string | null,
    limit: number | null,
    abortSignal?: AbortSignal
  ) => Promise<any>;
  getDossier: (listingKey: string) => Promise<any>;
  requestDossier: (listingKey: string, currentUrl?: string) => Promise<any>;
  getDossierList: () => Promise<Dossier[]>;
  getListing: (listingKey: string) => Promise<any>;
  disableListing: (listingKey: string) => Promise<any>;
  getRentomatic: (listing: Listing, comparablesIds: string[]) => Promise<Rentomatic>;
  gerProformaUrl: (listingKey: string) => Promise<any>;
  getProforma: (listing: Listing) => Promise<Proforma>;
  saveProforma: (listingKey: string, proforma: Proforma) => Promise<Proforma>;
  deleteProforma: (listingKey: string) => Promise<any>;
  getUserData: () => Promise<User>;
  getUserPreferences: () => Promise<UserPreferences>;
  saveUserPreferences: (preferences: UserPreferences) => Promise<void>;
  saveUserData: (user: User) => Promise<any>;
  addToFavorites: (listingKey: string) => Promise<any>;
  removeFromFavorites: (listingKey: string) => Promise<any>;
  getFavorites: () => Promise<ListingCompactView[]>;
  getSubscription: () => Promise<any>;
  manageSubscription: () => Promise<any>;
  createSubscription: () => Promise<string>;
  getListingInformation: (dom: string) => Promise<Listing>;
  getSubscriptionStatus: (sessionId: string | null) => Promise<any>;
  searchMetadata: (input_str: string) => Promise<any>;
  getSearchHistory: () => Promise<any>;
  updateSearchHistory: (address: string, v1PropertyId: string) => Promise<any>;
  getPropertyMetadata: (propertyId: string) => Promise<CoreLogicResponse>;
  getPropertyEstimationMetadata: (propertyId: string) => Promise<any>;
}

const ApiContext = createContext<ContextType | null>(null);

export const ApiProvider = ({ children }: any) => {
  const authContext = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);

  const login = async (username: string, password: string) => {
    const url = `${API_URL}/accounts/login/`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ login: username, password }),
    });

    if (!response.ok) {
      throw response;
    }
    const data = await response.json();
    authContext?.login(data.token);
    await authContext?.getToken()
  };

  const resetPassword = async (email: string) => {
    const url = `${API_URL}/accounts/send-reset-password-link/`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ login: email }),
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };

  const resetPasswordConfirm = async (
    password: string,
    password_confirm: string,
    data: any
  ): Promise<any> => {
    const url = `${API_URL}/accounts/reset-password/`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password,
        password_confirm,
        ...JSON.parse(atob(data)),
      }),
    });

    return response;
  };

  const register = async (
    user: Partial<User>
  ) => {
    const url = `${API_URL}/accounts/register/`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user.email,
        password: user.password,
        password_confirm: user.password_confirm,
        first_name: user.first_name,
        last_name: user.last_name
      }),
    });

    return response;
  };
  
  const getTerms = async (
    name: string, email: string
  ) => {
    const url = `${API_URL}/compliance/terms/`;
    const response = await fetch(url, {
      method: "POST",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        name
      }),
    });

    return response;
  };

  const createSubscription = async (): Promise<string> => {
    const url = `${API_URL}/payment/create/`;
    const response = await fetch(url, {
      credentials: "omit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    const data = await response.json();
    return data.clientSecret;
  };

  const getSubscriptionStatus = async (
    sessionId: string | null
  ): Promise<any> => {
    const url = `${API_URL}/payment/status/?session_id=${sessionId}`;
    const response = await fetch(url, {
      credentials: "omit",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    const data = await response.json();
    return data;
  };

  const getSubscription = async (): Promise<any> => {
    const url = `${API_URL}/payment/subscription/`;
    const response = await fetch(url, {
      credentials: "omit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };

  const manageSubscription = async (): Promise<any> => {
    const url = `${API_URL}/payment/subscription/manage/`;
    const response = await fetch(url, {
      credentials: "omit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };

  const logout = async () => {
    const url = `${API_URL}/accounts/logout/`;
    const response = await fetch(url, {
      credentials: "omit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    await response.json();
    await authContext?.logout();
  };

  const getListing = async (listingKey: string): Promise<any> => {
    setLoading(true)
    const url = `${API_URL}/listings/${listingKey}/`;

    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });
    setLoading(false)
    if (!response.ok) {
      throw response
    }
    return await response.json();
  };
  
  const disableListing = async (listingKey: string): Promise<any> => {
    setLoading(true)
    const url = `${API_URL}/listings/${listingKey}/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });
    setLoading(false)
    if (!response.ok) {
      throw response
    }
  };
  
  const getDossier = async (listingKey: string): Promise<any> => {
    setLoading(true)
    const url = `${API_URL}/dossier/${listingKey}/`;

    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });
    setLoading(false)
    if (!response.ok) {
      throw response
    }
    return await response.json();
  };
  const getDossierList = async (): Promise<Dossier[]> => {
    setLoading(true)
    const url = `${API_URL}/dossier/`;

    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });
    setLoading(false)
    if (!response.ok) {
      throw response
    }
    return await response.json();
  };
  const requestDossier = async (listingKey: string, currentUrl?: string): Promise<any> => {
    setLoading(true)
    const url = `${API_URL}/dossier/${listingKey}/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify({"currentUrl": currentUrl}),
    });
    setLoading(false)
    if (!response.ok) {
      throw response
    }
    return await response.json();
  };


  const getProforma = async (
    listing: Listing
  ): Promise<Proforma> => {
    const url = `${API_URL}/proformas/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify(listing),
    });

    const data = await response.json()

    if (!response.ok) {
      throw data;
    }

    return data;
  };
  
  const saveProforma = async (listingKey: string, proforma: Proforma): Promise<Proforma> => {
    const url = `${API_URL}/proformas/${listingKey}/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify(proforma),
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };
  
  const getListingInformation = async (dom: string): Promise<Listing> => {
    const url = `${API_URL}/listings/parse/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify({text: dom}),
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  const deleteProforma = async (listingKey: string): Promise<any> => {
    const url = `${API_URL}/proformas/${listingKey}/`;
    const response = await fetch(url, {
      credentials: "omit",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
  };

  const getRentomatic = async (
    listing: Listing,
    comparablesIds: string[]
  ): Promise<Rentomatic> => {
    const url = `${API_URL}/rentomatic/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify({listing, comparables_ids: comparablesIds}),
    });

    const data = await response.json()

    if (!response.ok) {
      throw data;
    }

    return data;
  };
  

  const gerProformaUrl = async (listingKey: string): Promise<any> => {
    const url = `${API_URL}/listings/${listingKey}/proforma/`;

    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  const getUserData = async (): Promise<User> => {
    const url = `${API_URL}/accounts/profile/`;

    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };
  
  const getUserPreferences = async (): Promise<UserPreferences> => {
    const url = `${API_URL}/preferences/`;

    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };
  const saveUserPreferences = async (preferences: UserPreferences): Promise<void> => {
    const url = `${API_URL}/preferences/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify({ ...preferences }),
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  const saveUserData = async (user: User): Promise<any> => {
    const url = `${API_URL}/accounts/profile/`;

    const response = await fetch(url, {
      credentials: "omit",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify({ ...user }),
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  };

  const getFavorites = async (): Promise<any> => {
    const url = `${API_URL}/user/favorites/`;
    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };

  const searchMetadata = async (input_str: string): Promise<any> => {
    const url = `${API_URL}/metadata/search/?input=${input_str}`;
    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };
  const updateSearchHistory = async (address: string, v1PropertyId: string): Promise<any> => {
    const url = `${API_URL}/metadata/search/history/`;
    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      method: "POST",
      body: JSON.stringify({"address": address, "v1PropertyId": v1PropertyId}),
    });

    if (!response.ok) {
      throw response;
    }
  };
  const getSearchHistory = async (): Promise<any> => {
    const url = `${API_URL}/metadata/search/history/`;
    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };
  const getPropertyMetadata = async (propertyId: string): Promise<CoreLogicResponse> => {
    const url = `${API_URL}/metadata/property/${propertyId}/`;
    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };
  const getPropertyEstimationMetadata = async (propertyId: string): Promise<any> => {
    const url = `${API_URL}/metadata/property/${propertyId}/estimation/`;
    const response = await fetch(url, {
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });

    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };

  const addToFavorites = async (listingKey: string): Promise<any> => {
    const url = `${API_URL}/user/favorites/${listingKey}/`;
    setLoading(true)
    const response = await fetch(url, {
      credentials: "omit",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });
    setLoading(false)
    if (!response.ok) {
      throw response;
    }
  };

  const removeFromFavorites = async (listingKey: string): Promise<any> => {
    const url = `${API_URL}/user/favorites/${listingKey}/`;
    setLoading(true)
    const response = await fetch(url, {
      credentials: "omit",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
    });
    setLoading(false)

    if (!response.ok) {
      throw response;
    }
  };

  const getListings = async (
    filters: any,
    offset: number,
    sort_by: string | null,
    limit: number | null,
    abortSignal?: AbortSignal
  ): Promise<any> => {
    setLoading(true)
    const url = `${API_URL}/listings/?offset=${offset}&sort_by=${sort_by}&limit=${limit}`;
    const response = await fetch(url, {
      signal: abortSignal,
      method: "POST",
      credentials: "omit",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${authContext?.userToken}`,
      },
      body: JSON.stringify(filters),
    });
    setLoading(false)
    if (!response.ok) {
      throw response;
    }
    return await response.json();
  };
  
  const value = useMemo(
    () => ({
      isLoading,
      login,
      register,
      getTerms,
      logout,
      resetPassword,
      resetPasswordConfirm,
      getListing,
      disableListing,
      getDossier,
      getDossierList,
      requestDossier,
      getProforma,
      saveProforma,
      deleteProforma,
      getRentomatic,
      getFavorites,
      getUserData,
      getUserPreferences,
      saveUserPreferences,
      saveUserData,
      addToFavorites,
      getListings,
      removeFromFavorites,
      getSubscription,
      createSubscription,
      manageSubscription,
      getSubscriptionStatus,
      gerProformaUrl,
      getListingInformation,
      searchMetadata,
      getPropertyMetadata,
      getPropertyEstimationMetadata,
      getSearchHistory,
      updateSearchHistory
    }),
    [authContext?.userToken, isLoading]
  );
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  return useContext(ApiContext);
};
