import React, { useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { CreamTypes } from "../interfaces";
import { Listing, ListingCompactView } from "../../api/interfaces/listing";

type CreamState = {
  sortBy: string;
  bbox: CreamTypes.BBox;
  filters: CreamTypes.Filters;
  activeListing?: Listing;
  creamUsageAccepted: boolean;
  listings: ListingCompactView[]
  total: number
  offset: number;
};

type ContextType = {
  state: CreamState;
  setState: (state: CreamState) => void;
};

const CreamContext = createContext<ContextType | null>(null);

export const CreamProvider = ({ children }: any) => {
  const [state, setState] = useState<CreamState>({
    bbox: {
      max_lat: 30.016087366800104,
      max_lon: -94.93810212637138,
      min_lat: 29.658713704344052,
      min_lon: -95.71538484121513,
      zoom_level: 10,
    },
    sortBy: "-year",
    filters: {
      estimated_rent_min: 0,
      estimated_return_min: 0,
      list_price_min: 400000,
      list_price_max: 900000,
    },
    creamUsageAccepted: false,
    listings: [],
    total: 0,
    offset: 0
  });

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  return (
    <CreamContext.Provider value={value}>{children}</CreamContext.Provider>
  );
};

export const useCream = () => {
  return useContext(CreamContext);
};
