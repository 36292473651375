import { RouterProvider, createHashRouter } from "react-router-dom";

import {
  AccountsRoutes,
  Favorites,
  NotFoundRoutes,
  DashboardRoutes,
  DossierRoutes,
  CreamWrapper,
  SelfDBRoutes,
  RentomaticWrapper,
  ProformaWrapper,
  HelpPage,
  SearchPage,
} from "./pages";
import { LayoutView } from "./components";
import * as LayoutComponents from "./components/layout";

import { ContentWithSideBar } from "./components/layout";

function App() {
  const router = createHashRouter([
    {
      element: <LayoutView />,
      children: [
        {
          path: "/search",
          element: (
            <LayoutComponents.ProtectedRoute>
              <SearchPage />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/rentomatic/",
          children: [
            {
              path: ":listingString",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <RentomaticWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <RentomaticWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/proforma/",
          children: [
            {
              path: ":listingString",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ProformaWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ProformaWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/dossier",
          children: [
            {
              path: ":listingString",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <DossierRoutes.DossierWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <DossierRoutes.DossierWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/cream",
          element: (
            <LayoutComponents.ProtectedRoute>
              <CreamWrapper />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/selfdb",
          children: [
            {
              path: ":propertyId",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <SelfDBRoutes.PropertyViewWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<SelfDBRoutes.PropertySearch />}
                    sideBarTitle='SELF db'
                    sidebar={<>
                      <p>Search SELF's property database.</p>
                    </>}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/favorites",
          element: (
            <LayoutComponents.ProtectedRoute>
              <ContentWithSideBar
                title='FAVORITES'
                sideBarTitle='HOME'
                center={<Favorites />}
                sidebar={<DashboardRoutes.SidebarMenu />}
              />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/history",
          element: (
            <LayoutComponents.ProtectedRoute>
              <ContentWithSideBar
                title='HISTORY'
                sideBarTitle='HOME'
                center={<Favorites />}
                sidebar={<DashboardRoutes.SidebarMenu />}
              />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/help",
          element: (
            <>
              <ContentWithSideBar
                center={<HelpPage />}
                sidebar={<DashboardRoutes.SidebarMenu />}
              />
            </>
          ),
        },
        {
          path: "/accounts",
          children: [
            {
              path: "profile",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<AccountsRoutes.Profile />}
                    sideBarTitle='HOME'
                    sidebar={<DashboardRoutes.SidebarMenu />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "preferences",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<AccountsRoutes.Preferences />}
                    title="Preferences"
                    sideBarTitle='Home'
                    sidebar={<DashboardRoutes.SidebarMenu />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "reset",
              element: (
                <ContentWithSideBar
                  sideBarTitle='FORGOT PASSWORD?'
                  center={<DashboardRoutes.HomePage />}
                  sidebar={<AccountsRoutes.ForgotPassword />}
                />
              ),
            },
            {
              path: "reset_confirm",
              element: (
                <ContentWithSideBar
                  sideBarTitle='RESET PASSWORD'
                  center={<DashboardRoutes.HomePage />}
                  sidebar={<AccountsRoutes.ResetPassword />}
                />
              ),
            },
            {
              path: "login",
              element: (
                <ContentWithSideBar
                  sideBarTitle='LOGIN'
                  center={<DashboardRoutes.HomePage />}
                  sidebar={<AccountsRoutes.Login />}
                />
              ),
            },
            {
              path: "logout",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <AccountsRoutes.Logout />,
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "register",
              children: [
                {
                  path: "",
                  element: (
                    <ContentWithSideBar
                      sideBarTitle='CREATE ACCOUNT'
                      center={<DashboardRoutes.HomePage />}
                      sidebar={<AccountsRoutes.Register />}
                    />
                  ),
                },
                {
                  path: "terms",
                  element: <AccountsRoutes.AcceptTerms />,
                },
                {
                  path: "confirmed",
                  element: (
                    <ContentWithSideBar
                      sideBarTitle='REGISTRATION CONFIRMED'
                      center={<DashboardRoutes.HomePage />}
                      sidebar={<AccountsRoutes.RegisterConfirmation />}
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "/dashboard",
          children: [
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    title='SERVICES'
                    sideBarTitle='HOME'
                    center={<DashboardRoutes.Dashboard />}
                    sidebar={<DashboardRoutes.SidebarMenu />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "subscribe",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<DashboardRoutes.Dashboard />}
                    sidebar={<DashboardRoutes.Subscription />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "subscribe/success",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<DashboardRoutes.Dashboard />}
                    sidebar={<DashboardRoutes.FinishSubscription />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/feedback",
          element: (
            <LayoutComponents.ProtectedRoute>
              <ContentWithSideBar
                title='SUPPORT'
                center={<DashboardRoutes.FeedbackPage />}
                sideBarTitle='HOME'
                sidebar={<DashboardRoutes.SidebarMenu />}
              />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/not_found/search",
          element: (
            <ContentWithSideBar
              title='SEARCH PAGE NOT SUPPORTED'
              center={
                <>
                  <NotFoundRoutes.SearchPage />
                  <NotFoundRoutes.ErrorBox />
                </>
              }
              sideBarTitle='HOME'
              sidebar={<DashboardRoutes.SidebarMenu />}
            />
          ),
        },
        {
          path: "/not_found/apartments",
          element: (
            <ContentWithSideBar
              title='PAGE NOT SUPPORTED'
              center={
                <>
                  <NotFoundRoutes.Apartments />
                  <NotFoundRoutes.ErrorBox />
                </>
              }
              sideBarTitle='HOME'
              sidebar={<DashboardRoutes.SidebarMenu />}
            />
          ),
        },
        {
          path: "/not_found/not_supported_type",
          element: (
            <ContentWithSideBar
              title='PROPERTY TYPE NOT SUPPORTED'
              center={
                <>
                  <NotFoundRoutes.PropertyNotSupported />
                  <NotFoundRoutes.ErrorBox />
                </>
              }
              sideBarTitle='HOME'
              sidebar={<DashboardRoutes.SidebarMenu />}
            />
          ),
        },
        {
          path: "/not_found/not_supported_region",
          element: (
            <ContentWithSideBar
              title='REGION NOT SUPPORTED'
              center={
                <>
                  <NotFoundRoutes.RegionNotSupported />
                  <NotFoundRoutes.ErrorBox />
                </>
              }
              sideBarTitle='HOME'
              sidebar={<DashboardRoutes.SidebarMenu />}
            />
          ),
        },
        {
          path: "/not_found",
          element: (
            <LayoutComponents.ProtectedRoute>
              <ContentWithSideBar
                title='STILL SEARCHING?'
                center={
                  <>
                    <NotFoundRoutes.Default />
                    <NotFoundRoutes.ErrorBox />
                  </>
                }
                sideBarTitle='HOME'
                sidebar={<DashboardRoutes.SidebarMenu />}
              />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          element: (
            <LayoutComponents.ProtectedRoute>
              <ContentWithSideBar
                title='STILL SEARCHING?'
                center={
                  <>
                    <NotFoundRoutes.Default />
                    <NotFoundRoutes.ErrorBox />
                  </>
                }
                sideBarTitle='HOME'
                sidebar={<DashboardRoutes.SidebarMenu />}
              />
            </LayoutComponents.ProtectedRoute>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
