import styled from "styled-components";
import { Image } from "react-bootstrap";

import { SearchTypeAhead } from "./components";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import { Item } from "./components/SearchTypeAhead";
import { useEffect, useState } from "react";
import { useListing } from "../../hooks/useListing";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const Logo = styled.div`
  width: 200px;
  margin: 0px 0px;
  img {
    width: 100%;
  }
`;

const Recent = styled.div`
  width: 400px;
  font-size: 16px;
  text-align: left;

  button {
    border: none;
    background: none;
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
  }
`

export const PropertySearch = () => {
  const navigate = useNavigate();
  const apiContext = useApi();
  const [options, setOptions] = useState<Item[]>([]);
  const [currentAddress, setCurrentAddress] = useState<string|undefined|null>(null);
  const listingContext = useListing();

  const viewProperty = (propertyId: string, address: string) => {
    navigate(`/selfdb/${propertyId}`, {
      state: { address },
    });
  };

  const getUserHistory = async () => {
    const results = await apiContext?.getSearchHistory();
    results.length = Math.min(results.length, 10);
    setOptions(results);
  };

  useEffect(() => {
    getUserHistory()
  }, [])
  
  useEffect(() => {
    if (listingContext && listingContext.listing) {
      setCurrentAddress(listingContext.listing.unparsed_address ?? undefined)
    } else {
      setCurrentAddress(undefined)
    }
  }, [listingContext])

  return (
    <Wrapper>
      <Logo className="mb-3">
        <Image src='/images/self-logo-black-transparent.png' />
      </Logo>
      <SearchTypeAhead value={currentAddress} onChange={viewProperty} />
      {options.length > 0 && <>
        <Recent className="mt-3">
          <div className="mb-1 mt-3"><b>Recent searches</b></div>
          {options.map(x => <button key={x.v1PropertyId} onClick={() => viewProperty(x.v1PropertyId, x.address)}>{x.address}</button>)}  
        </Recent>
      </>}
    </Wrapper>
  );
};
