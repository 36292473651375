import { useEffect, useState } from "react";

import { useApi } from "../../../api/useApi";
import { Proforma as ProformaType } from "../../../api/interfaces/proforma";

import { useNavigate, useParams } from "react-router-dom";
import hash from "object-hash";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Proforma } from "./Proforma";
import { useListing } from "../../hooks/useListing";
import { Listing } from "../../../api/interfaces/listing";

export const ProformaWrapper = () => {
  const { listingString } = useParams();
  const navigate = useNavigate();
  const apiContext = useApi();
  const listingContext = useListing();
  const [proforma, setProforma] = useState<ProformaType | null>(null);
  const [hashKey, setHash] = useState<string>("");
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const listingKey = listingString ?? listingContext?.listingKey;

  const handleLoadProforma = async (listing: Listing): Promise<void> => {
    const data = await apiContext?.getProforma(listing);
    if (data) {
      setProforma(data);
    }
  };

  const onSave = async (proforma: ProformaType): Promise<void> => {
    setInitialLoading(true);
    const newProforma = await apiContext?.saveProforma(listingKey!, proforma);
    if (newProforma) {
      setProforma(newProforma);
    }
    setInitialLoading(false);
  };

  const onDelete = async (): Promise<void> => {
    setInitialLoading(true);
    await apiContext?.deleteProforma(listingKey!);
    // await handleLoadProforma();
    setInitialLoading(false);
  };

  const onReset = () => {
    setProforma(proforma);
    setHash(
      hash({
        time: new Date().valueOf(),
      })
    );
  };

  useEffect(() => {
    if (listingContext?.listing) {
      setInitialLoading(true);
      handleLoadProforma(listingContext.listing)
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setInitialLoading(false);
        });
    } else {
      setInitialLoading(false);
    }
  }, [listingContext?.listing]);

  if (listingKey === null) {
    navigate("/not_found/not_supported_type");
  }

  if (initialLoading || !proforma || !listingContext) {
    return <LoadingIndicator />;
  }

  if (listingKey === null ) {
    navigate("/dashboard");
  }
  return (
    <>
      <Proforma
        key={hashKey}
        proforma={proforma}
        listing={listingContext.listing}
        onSave={onSave}
        onReset={onReset}
        onDelete={onDelete}
      />
    </>
  );
};
