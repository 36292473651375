import { useEffect, useState } from "react";
import { Module } from "./module.type";
import { useApi } from "../../../api/useApi";
import { useAuth } from "../../hooks/useAuth";

export function useModules(): [Module[], boolean] {
  const context = useApi();
  const authContext = useAuth();
  const defaultModules: Module[] = [
    {
      added: true,
      moduleId: "rentomatic",
      title: "RENT-O-MATIC: Rent estimates",
      description:
        "Estimate potential rent on every active listing.  See nearby and recent comparables used to derive the estimate",
      price: "Added",
      imagePath: "/images/module_icons/rent-o-matic.png",
      url: "/rentomatic",
    },
    {
      added: false,
      moduleId: "proformator",
      title: "PROFORMANATOR: Proforma generator",
      description:
        "Eliminate the tedium of analyzing listings for investability. Auto-filled analysis for one-click profit & loss on every listing.",
      price: "$9.95/mo",
      imagePath: "/images/module_icons/proformanator-logo.png",
      url: "/proforma",
    },
    {
      added: false,
      moduleId: "dossier",
      title: "DOSSIER: Obtain listing disclosures, attachments, history and reports",
      description: "Deep dive any listing, verify owner information, non-public documents, complete listing history, and more.",
      price: "1 credit",
      imagePath: "/images/module_icons/dossier.png",
      url: "/dossier",
    },
    {
      added: false,
      moduleId: "cream",
      title: "C.R.E.A.M: Filter listings by potential return",
      description: "Cap rates everywhere. Search our opinion of rent and return on all available listings to quickly find profitable properties.",
      price: "$34.95/mo",
      imagePath: "/images/module_icons/cream_logo.png",
      url: "/cream",
    },
    {
      added: false,
      moduleId: "self.db",
      title: "Self DB: Address search",
      description: "Investigate any property, not just listings. We provide rent and sales price estimations, comparables, and owner contact information.",
      price: "PRICE",
      imagePath: "/images/module_icons/selfdb.png",
      url: "/selfdb",
    },
  ];

  const [modules, setModules] = useState(defaultModules);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let intervalId: NodeJS.Timer
    let totalTimes: number
    const fetchData = async () => {
      try {
        const data = await context?.getUserData();
        authContext?.setUser(data!)
        totalTimes += 1
        if (data?.has_subscription) {
          setModules([
            modules[0],
            {
              ...modules[1],
              added: true,
            },
            {
              ...modules[2],
              added: true,
            },
            {
              ...modules[3],
              added: true,
            },
            {
              ...modules[4],
              added: true,
            },
          ]);
          authContext?.setPaidUser(true)
        }
        if (totalTimes >= 15 || data?.has_subscription) {
          clearInterval(intervalId)
        }
      } catch (error) {
        clearInterval(intervalId)
      } finally {
        setLoading(false);
      }
    };

    intervalId = setInterval(() => {
      fetchData().catch(console.error);
    }, 3000)

  }, [authContext?.userToken]);
  return [modules, loading];
}
