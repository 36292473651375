import React, { useEffect } from "react";
import { useState } from "react";
import { useApi } from "../../../api/useApi";
import { Button, Form } from "react-bootstrap";
import { User as UserIcon } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { User } from "../../../api/interfaces/user";

export const Register = () => {
  const context = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const [user, setUser] = useState<Partial<User>>(state?.user || {});
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [signedTerms, setSignedTerms] = useState<boolean>(
    state?.signed === true
  );
  const [isLoading, setIsLoading] = useState(false);
  const [agreeWithTerms, setAgreeWithTerms] = useState(false || state?.signed);

  const handleRegister = async (e: any) => {
    e.preventDefault();
    if (signedTerms) {
      await createAccount();
    } else {
      const terms = await getTerms();
      navigate("/accounts/register/terms", {
        state: { user, terms  },
      });
    }
  };

  const getTerms = async (): Promise<string|null> => {
    setIsLoading(true);
    try {
      const response = await context?.getTerms(
        `${user.first_name} ${user.last_name}`,
        user?.email || ""
      );
      if (!response.ok) {
        alert("Registration error");
      }
      return await response.text();
    } catch (error) {
      alert("Registration error");
    }
    setIsLoading(false);
    return null
  };

  const createAccount = async () => {
    setIsLoading(true);
    try {
      const response = await context?.register(user);
      if (!response.ok) {
        const errors = await response.json();
        setErrors(errors);
      } else {
        navigate("/accounts/login", {
          state: { email: user.email, password: user.password },
        });
      }
    } catch (error) {
      alert("Registration error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (signedTerms) {
      createAccount();
    }
  }, [signedTerms]);

  const TermsOfUseLink = () => {
    return (
      <>
        I agree with{" "}
        <a href='https://www.selfrealty.com/terms' target='_blank'>
          Terms and Conditions
        </a>
      </>
    );
  };

  return (
   
      <Form onSubmit={handleRegister}>
        <Form.Group className='mb-3'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            onChange={(e) => {
              setUser({
                ...user,
                first_name: e.target.value,
              });
              setSignedTerms(false);
            }}
            value={user.first_name}
            type='text'
            required
            placeholder='Enter First Name'
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            onChange={(e) => {
              setUser({
                ...user,
                last_name: e.target.value,
              });
              setSignedTerms(false);
            }}
            value={user.last_name}
            type='text'
            required
            placeholder='Enter Last Name'
          />
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            onChange={(e) =>
              setUser({
                ...user,
                email: e.target.value,
              })
            }
            autoComplete='username email'
            name='email'
            value={user.email}
            type='email'
            required
            isInvalid={errors["email"]}
            placeholder='Enter email'
          />
          <Form.Control.Feedback type='invalid'>
            {errors["email"] && errors["email"][0]}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            onChange={(e) =>
              setUser({
                ...user,
                password: e.target.value,
              })
            }
            name='password'
            value={user.password}
            type='password'
            autoComplete='new-password'
            isInvalid={errors["password"]}
            required
            placeholder='Password'
          />
          <Form.Control.Feedback type='invalid'>
            {errors["password"] && errors["password"][0]}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-3'>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            onChange={(e) =>
              setUser({
                ...user,
                password_confirm: e.target.value,
              })
            }
            value={user.password_confirm}
            type='password'
            required
            name='password_confirm'
            isInvalid={errors["password_confirm"]}
            placeholder='Password'
          />
          <Form.Control.Feedback type='invalid'>
            {errors["password_confirm"] && errors["password_confirm"][0]}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <div className='mb-3'>
            <Form.Check
              required
              checked={agreeWithTerms}
              onChange={(e) => setAgreeWithTerms(!agreeWithTerms)}
              type='checkbox'
              label={<TermsOfUseLink />}
            />
          </div>
        </Form.Group>
        <Button
          variant='primary'
          type='submit'
          disabled={isLoading || !agreeWithTerms}
        >
          {isLoading ? (
            <>
              <LoadingIndicator />
              &nbsp; Loading...
            </>
          ) : (
            "Create Account"
          )}
        </Button>
        <p className='mt-2 text-center'>
          Already have an account? <Link to='/accounts/login'>Login</Link>
        </p>
      </Form>

  );
};
